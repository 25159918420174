import {
  FolderOpenOutlined,
  LineChartOutlined,
  NotificationOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useState, useEffect } from 'react';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // получаем текущий путь
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
    navigate('/');
  };

  const items = [
    {
      label: <a href="/">Клиенты</a>,
      key: 'сlients',
      icon: <UsergroupAddOutlined />,
    },
    {
      label: <a href="/users">Пользователи и доступ</a>,
      key: 'users',
      icon: <UserOutlined />,
    },
    {
      label: <a href="/managers">Менеджеры</a>,
      key: 'managers',
      icon: <UserOutlined />,
    },
    {
      label: <a href="/orders">Заказы</a>,
      key: 'orders',
      icon: <ShoppingCartOutlined />,
    },
    {
      label: <button onClick={handleLogout}>Выйти</button>,
      key: 'Выйти',
      icon: <PoweroffOutlined className="icon" />,
    },
  ];

  const [current, setCurrent] = useState(''); // начальное состояние

  useEffect(() => {
    // устанавливаем ключ в зависимости от текущего пути
    switch (location.pathname) {
      case '/':
        setCurrent('сlients');
        break;
      case '/users':
        setCurrent('users');
        break;
      case '/managers':
        setCurrent('managers');
        break;
      case '/orders':
        setCurrent('orders');
        break;
      default:
        setCurrent('');
    }
  }, [location]); // обновляем активный элемент при изменении пути

  const onClick = (e) => {
    setCurrent(e.key); // обновляем состояние при клике
  };

  return (
    <>
      <Menu
        onClick={onClick} // обработчик клика
        selectedKeys={[current]} // активная вкладка
        mode="horizontal"
        items={items}
      />
    </>
  );
};

export default Navbar;
