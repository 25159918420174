import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { getOrders, updateOrder, deleteOrder, addOrder } from '../api';
import { Space, Table, Tag, Modal, Button, Form, Input, message, Select, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

export default function Orders() {
  const { data = [], refetch } = useQuery({
    queryKey: ['orders'],
    queryFn: getOrders,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [form] = Form.useForm();

  const { mutate: mutateAdd } = useMutation(addOrder, {
    onSuccess: () => {
      message.success('Заказ успешно добавлен!');
      queryClient.invalidateQueries(['orders']);
      setIsModalVisible(false);
    },
    onError: () => {
      message.error('Ошибка при добавлении заказа.');
    },
  });

  const { mutate: mutateUpdate } = useMutation(updateOrder, {
    onSuccess: () => {
      message.success('Заказ успешно обновлен!');
      queryClient.invalidateQueries(['orders']);
      setIsModalVisible(false);
    },
    onError: () => {
      message.error('Ошибка при обновлении заказа.');
    },
  });

  const { mutate: mutateDelete } = useMutation(deleteOrder, {
    onSuccess: () => {
      message.success('Заказ удалён!');
      queryClient.invalidateQueries(['orders']);
      setIsModalVisible(false);
    },
    onError: () => {
      message.error('Ошибка при удалении заказа.');
    },
  });

  const dataTable = Array.isArray(data)
    ? data.map((el) => ({
        key: el._id,
        orderId: el._id,
        clientName: el.clientId.legalName || 'Не указан',
        clientEmail: el.clientId.email,
        clientPhone: el.clientId.phone,
        status: el.status || 'Не указан',
        deliveryAddress: el.departmentId?.deliveryAddress,
        items: el.items || [],
        sumRub: el.sumRub || [],
        date: moment(el.date).format('YYYY-MM-DD'),
        created: moment(el.created).format('YYYY-MM-DD, HH:mm:ss'),
      }))
    : [];

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Дата заказа',
      dataIndex: 'created',
      key: 'created',
      width: '10%',
      ...getColumnSearchProps('created'),
    },
    {
      title: 'Дата доставки',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
      ...getColumnSearchProps('date'),
    },
    {
      title: 'Сумма заказа',
      dataIndex: 'sumRub',
      key: 'sumRub',
      width: '10%',
      ...getColumnSearchProps('sumRub'),
    },
    {
      title: 'Имя клиента',
      dataIndex: 'clientName',
      key: 'clientName',
      width: '20%',
      ...getColumnSearchProps('clientName'),
    },
    {
      title: 'Email клиента',
      dataIndex: 'clientEmail',
      key: 'clientEmail',
      width: '15%',
      ...getColumnSearchProps('clientEmail'),
    },
    {
      title: 'Телефон клиента',
      dataIndex: 'clientPhone',
      key: 'clientPhone',
      width: '10%',
      ...getColumnSearchProps('clientPhone'),
    },
    {
      title: 'Адрес доставки',
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
      width: '30%',
    },
  ];

  const handleRowClick = (record) => {
    setIsAddMode(false);
    setSelectedOrder(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleEdit = () => {
    mutateUpdate({ ...selectedOrder, ...form.getFieldsValue() });
  };

  const handleAdd = () => {
    mutateAdd(form.getFieldsValue());
  };

  return (
    <>
      <div style={{ margin: 16 }}>
        <span>Всего заказов: {dataTable.length}</span>
        <Button
          type="primary"
          style={{ marginLeft: 16 }}
          onClick={() => {
            setIsAddMode(true);
            form.resetFields();
            setIsModalVisible(true);
          }}
        >
          Добавить заказ
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataTable}
        onRow={(record) => ({ onClick: () => handleRowClick(record) })}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <Table
        //       columns={[
        //         { title: 'Название продукта', dataIndex: 'productName', key: 'productName' },
        //         { title: 'Цена (RUB)', dataIndex: 'priceRub', key: 'priceRub' },
        //         { title: 'Количество', dataIndex: 'count', key: 'count' },
        //       ]}
        //       dataSource={record.items.map((item, index) => ({
        //         key: index,
        //         productName: item.productName,
        //         priceRub: item.priceRub,
        //         count: item.count,
        //       }))}
        //       pagination={false}
        //     />
        //   ),
        // }}
      />
      <Modal
        title={isAddMode ? 'Добавить заказ' : 'Редактировать заказ'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          isAddMode ? (
            <Button key="add" type="primary" onClick={handleAdd}>
              Добавить
            </Button>
          ) : (
            <>
              <Button key="delete" danger onClick={() => mutateDelete(selectedOrder.key)}>
                Удалить
              </Button>
              <Button key="save" type="primary" onClick={handleEdit}>
                Сохранить
              </Button>
            </>
          ),
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Отмена
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Имя клиента" name="clientName" rules={[{ required: true, message: 'Введите имя клиента' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Email клиента"
            name="clientEmail"
            rules={[{ required: true, message: 'Введите email клиента' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Телефон клиента"
            name="clientPhone"
            rules={[{ required: true, message: 'Введите телефон клиента' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Адрес доставки"
            name="deliveryAddress"
            rules={[{ required: true, message: 'Введите адрес доставки' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Дата доставки" name="date" rules={[{ required: true, message: 'Введите дату доставки' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Сумма заказа" name="sumRub">
            <Input />
          </Form.Item>
        </Form>
        {!isAddMode && (
          <Collapse defaultActiveKey={[]}>
            <Collapse.Panel header="Список товаров" key="1">
              <Table
                columns={[
                  { title: 'Название продукта', dataIndex: 'productName', key: 'productName' },
                  { title: 'Цена (RUB)', dataIndex: 'priceRub', key: 'priceRub' },
                  { title: 'Количество', dataIndex: 'count', key: 'count' },
                ]}
                dataSource={selectedOrder?.items?.map((item, index) => ({
                  key: index,
                  productName: item.productName,
                  priceRub: item.priceRub,
                  count: item.count,
                }))}
                pagination={false}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      </Modal>
    </>
  );
}
